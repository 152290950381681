<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('notifications.details')"
      :paths="[{title:$t('settings.title'),link:'settings'},{title:$t('notifications.title'),link:'system-notifications'}]"
    />
    <section class='wameed-dashboard-page-content_body coupons_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'>
          <b-card no-body class='wameed-card'>


            <div class='package-details-table bg-white rounded-14'>
              <h1 class='package-details-table__title text-font-main text-med-14'>
                {{ $t('notifications.details') }}
              </h1>

              <detail-row
                :title="$t('discounts.table.name')"
                :value='detail.title'
              />


              <detail-row
                :title="$t('settings.notifications.image')"
              >
                <template v-slot:value>
                  <img
                    :src='detail.image'
                    class='img-fluid'
                    style='max-width: 100px;border-radius:8px'
                  />
                </template>
              </detail-row>
              <detail-row
                :title="$t('notifications.table.target')"
              >
                <template v-slot:value>

                  {{ formatNotificationTargets(detail.targets) }}
                </template>
              </detail-row>

              <detail-row
                :title="$t('settings.notifications.body')"
                :value='detail.content'
              />





              <detail-row
                :title="$t('notifications.table.date')"
                :value='detail.created_at'
              />
              <detail-row
                :title="$t('notifications.table.scheduledDate')"
                :value='detail.scheduled_at'
              />



            </div>
          </b-card>
        </b-col>
      </b-row>

    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import { wameedNotify } from 'wameed-ui/dist/wameed-ui.esm';
import DetailRow from '@/views/pages/discounts/components/detailRow.vue';
import DiscountStatusBadge from '@/views/pages/discounts/components/discountStatusBadge.vue';
import Usage from '@/views/pages/discounts/detail/usage.vue';
import DiscountCategory from '@/views/pages/discounts/components/discountCategory.vue';

import { notificationTarget } from '@/enums/notificationTarget.enum';
import { filterUserTarget } from '@/enums/targetTypes.enum';


export default {
  components: {
    DiscountCategory,
    Usage,
    DiscountStatusBadge,
    DetailRow,

    PageHeader,
    wameedNotify
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      detail: 'admin/settings/notifications/getDetail'
    })


  },

  methods: {

    formatNotificationTargets(targets) {

      let formattedTargets = '';
      if(!targets) return formattedTargets;
      targets.forEach((target, index) => {
        if (target.key === notificationTarget.usersType) {
          formattedTargets += filterUserTarget(target.value).name;
        } else if (target.key === notificationTarget.gender) {
          let genders = target.value.toString().split(',');
          if (genders.length > 1) {
            // formattedTargets += this.$t('common.males') + ',' + this.$t('common.females');
          } else {
            if (genders[0] == 1) {
              formattedTargets += this.$t('common.males');
            } else {
              formattedTargets += this.$t('common.females');
            }
          }
        } else if (target.key === notificationTarget.age) {
          let value = target.value.toString().split(',');
          formattedTargets += this.$t('common.from') + ' ' + value[0] + ' ' + this.$t('common.to') + ' ' + value[1] + ' ' + this.$t('common._year');

        }

        if (index !== targets.length - 1 && formattedTargets !== '') {
          formattedTargets += ', ';
        }
      });
      return formattedTargets;
    }
  },


  async created() {

    await this.$store.dispatch('admin/settings/notifications/detail', { id: this.$route.params.id });

  }
};
</script>
